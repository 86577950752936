﻿* {
    &:active,
    :focus {
      outline: none !important;  // 1
    }
  }

  a:not(.mat-button):not(.mat-raised-button):not(.mat-fab):not(.mat-mini-fab):not([mat-list-item]) {
      color: rgba(0, 0, 0, 0.87); // 2
    }
