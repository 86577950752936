/* You can add global styles to this file, and also import other style files */



html,
body {
    min-height: 100vh;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


/* https://www.amadousall.com/the-good-parts-of-bootstrap-4-you-are-missing-in-your-angular-material-projects/ */

@import "variables";
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";

// // Import Bootstrap Reboot
// @import "bootstrap/scss/reboot";
// @import "bootstrap/scss/grid"; // add the grid
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/list-group";
@import "bootstrap/dist/css/bootstrap.css";

@import "reset";


@media print {
    .isPrinting>* {
        display: none;
    }

    .isPrinting app-print-layout {
        display: block;
    }
}


fieldset,
legend {
    all: revert;
}

.rsPageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}

.rsGridHeader>div {
    height: 2em;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}